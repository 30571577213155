<div class="d-flex justify-content-between align-items-center mb-2">
  <label class="form-label mb-0">Heatmap Rows:</label>
  <button
    data-spec="add-tree-graph-heatmap-btn"
    class="btn btn-primary btn-sm"
    (click)="handleAddHeatmapRow()"
    [disabled]="isAddingHeatmapDisabled$ | async"
    [ngbTooltip]="
      (isHeatmapLimitReached$ | async) === true
        ? 'The maximum number of heatmap rows supported is ' + heatmapConfigOptions.maxRows
        : ''
    "
  >
    <fa-icon class="me-2" [icon]="faPlus"></fa-icon>Add
  </button>
</div>
@for (
  heatmapControlGroup of heatmapConfigControls.controls;
  track heatmapControlGroup;
  let index = $index
) {
  <div>
    <bx-collapsible-card
      data-spec="tree-graph-heatmap-config-card"
      [compact]="true"
      [collapseByDefault]="heatmapControlGroup.controls.collapsed.value"
      [title]="mapMetadataKeyToName(heatmapControlGroup.controls.metadataKey.value)"
      (collapseChange)="heatmapControlGroup.controls.collapsed.setValue($event)"
    >
      <div class="d-flex justify-content-between" title>
        <div class="flex-column">
          <div>{{ mapMetadataKeyToName(heatmapControlGroup.controls.metadataKey.value) }}</div>
          <bx-color-palette
            [palette]="heatmapControlGroup.controls.colorPalette.value"
          ></bx-color-palette>
        </div>
        @if (heatmapConfigControls.length > 1) {
          <fa-icon
            data-spec="remove-collapse-card-btn"
            class="float-end bees"
            (click)="handleRemoveHeatmapRow(index)"
            [icon]="faTimes"
          ></fa-icon>
        }
      </div>
      <form [formGroup]="heatmapControlGroup">
        <div class="row m-2 flex-column">
          <label class="form-label form-label-sm">Color Based On:</label>
          <bx-select
            [smallSize]="false"
            [selectOptions]="metadataOptions"
            [formControl]="heatmapControlGroup.controls.metadataKey"
          ></bx-select>
        </div>
        <div class="row m-2 flex-column">
          <label class="form-label form-label-sm">Color Palette:</label>
          @if (
            getHeatmapConfig(
              heatmapControlGroup.controls.metadataKey.value,
              heatmapControlGroup.controls.colorPalette.value
            );
            as heatmapConfig
          ) {
            <bx-select-color-palette
              [formControl]="heatmapControlGroup.controls.colorPalette"
              [isCategorical]="heatmapConfig.isCategorical"
              [numCategories]="heatmapConfig.numGroups"
            ></bx-select-color-palette>
          }
        </div>
      </form>
    </bx-collapsible-card>
  </div>
}
