@if (anyGraphsAvailable$ | async) {
  <bx-toolstrip class="toolbar d-flex justify-content-between">
    @if (
      { pngEnabled: pngExportEnabled$ | async, tableEnabled: tableExportEnabled$ | async };
      as exports
    ) {
      @if (exports.pngEnabled || exports.tableEnabled) {
        <bx-toolstrip-item>
          <div class="btn-group" ngbDropdown>
            <button
              id="button-basic"
              [disabled]="loading$ | async"
              ngbDropdownToggle
              type="button"
              class="btn btn-light dropdown-toggle"
              aria-controls="dropdown-basic"
            >
              Export <span class="caret"></span>
            </button>
            <ul
              id="dropdown-basic"
              ngbDropdownMenu
              class="dropdown-menu"
              role="menu"
              aria-labelledby="button-basic"
            >
              @if (exports.pngEnabled) {
                <li
                  role="menuitem"
                  ngbTooltip="Export this graph as a png image"
                  container="body"
                  placement="right"
                >
                  <button ngbDropdownItem (click)="exportAsImage()">
                    <span>Image (png)</span>
                  </button>
                </li>
              }
              @if (exports.tableEnabled) {
                <li
                  role="menuitem"
                  ngbTooltip="Export this graph as a csv"
                  container="body"
                  placement="right"
                >
                  <button ngbDropdownItem (click)="exportAsTable()">Table (csv)</button>
                </li>
              }
            </ul>
          </div>
        </bx-toolstrip-item>
      }
    }
    @if (zoomControls$ | async; as zoomControls) {
      <bx-toolstrip-item>
        <bx-graph-zoom [zoomControls]="zoomControls"></bx-graph-zoom>
      </bx-toolstrip-item>
    }
    <bx-toolstrip-item>
      @if (graphWarning$ | async; as warning) {
        <div class="alert alert-sm alert-warning px-2 py-1 mb-0" role="alert">
          <small class="text-truncate mx-1">
            <fa-icon [icon]="warningIcon" class="mr-1"></fa-icon>
            {{ warning }}
          </small>
        </div>
      }
    </bx-toolstrip-item>
  </bx-toolstrip>
}
@if (
  {
    loading: (loading$ | async) && (anyGraphsAvailable$ | async),
    message: message$ | async,
  };
  as state
) {
  <div class="d-flex flex-grow-1 flex-shrink-1 overflow-hidden position-relative">
    @if (selectedGraph$ | async; as graph) {
      @if (state.loading) {
        <bx-page-message class="flex-grow-1">
          <bx-loading></bx-loading>
        </bx-page-message>
      }
      @if (state.message) {
        <bx-page-message class="flex-grow-1">
          {{ state.message }}
        </bx-page-message>
      }

      @if (showGraph$ | async) {
        <div
          class="d-flex flex-grow-1 flex-shrink-1 overflow-hidden"
          [ngClass]="{
            'd-none': state.loading || state.message,
            'd-flex': !state.loading && !state.message,
          }"
        >
          @switch (graph.id) {
            @case ('noGraph') {
              <div>{{ graph.name }}</div>
            }
            @case ('sankey') {
              <bx-ngs-sankey-plot
                #exportable
                [documentID]="documentID$ | async"
                [tablesForDocument]="tablesForDocument$ | async"
                [graphIdForSidebar]="graphIdForSidebar$ | async"
                (controlsUpdated)="onControlsUpdated($event)"
                (pngExportEnabledUpdated)="onPngExportEnabledUpdated($event)"
                (tableExportEnabledUpdated)="onTableExportEnabledUpdated($event)"
                (graphWarningUpdated)="onGraphWarning($event)"
              ></bx-ngs-sankey-plot>
            }
            @case ('annotationRates') {
              <bx-ngs-annotation-rates-graph
                #exportable
                [documentID]="documentID$ | async"
                [graphIdForSidebar]="graphIdForSidebar$ | async"
                (controlsUpdated)="onControlsUpdated($event)"
                (pngExportEnabledUpdated)="onPngExportEnabledUpdated($event)"
                (tableExportEnabledUpdated)="onTableExportEnabledUpdated($event)"
                (graphWarningUpdated)="onGraphWarning($event)"
              >
              </bx-ngs-annotation-rates-graph>
            }
            @case ('clusterDiversity') {
              <bx-ngs-cluster-diversity-graph
                #exportable
                [documentID]="documentID$ | async"
                [graphIdForSidebar]="graphIdForSidebar$ | async"
                (controlsUpdated)="onControlsUpdated($event)"
                (pngExportEnabledUpdated)="onPngExportEnabledUpdated($event)"
                (tableExportEnabledUpdated)="onTableExportEnabledUpdated($event)"
                (graphWarningUpdated)="onGraphWarning($event)"
              ></bx-ngs-cluster-diversity-graph>
            }
            @case ('clusterLengths') {
              <bx-ngs-cluster-lengths-graph
                #exportable
                [documentID]="documentID$ | async"
                [graphIdForSidebar]="graphIdForSidebar$ | async"
                (controlsUpdated)="onControlsUpdated($event)"
                (pngExportEnabledUpdated)="onPngExportEnabledUpdated($event)"
                (tableExportEnabledUpdated)="onTableExportEnabledUpdated($event)"
                (graphWarningUpdated)="onGraphWarning($event)"
              ></bx-ngs-cluster-lengths-graph>
            }
            @case ('clusterSizes') {
              <bx-ngs-cluster-sizes-graph
                #exportable
                [documentID]="documentID$ | async"
                [graphIdForSidebar]="graphIdForSidebar$ | async"
                (controlsUpdated)="onControlsUpdated($event)"
                (pngExportEnabledUpdated)="onPngExportEnabledUpdated($event)"
                (tableExportEnabledUpdated)="onTableExportEnabledUpdated($event)"
                (graphWarningUpdated)="onGraphWarning($event)"
              ></bx-ngs-cluster-sizes-graph>
            }
            @case ('clusterNumbers') {
              <bx-ngs-cluster-numbers-graph
                #exportable
                [aminoAcidOrNucleotide]="'aminoacid'"
                [documentID]="documentID$ | async"
                [graphIdForSidebar]="graphIdForSidebar$ | async"
                (controlsUpdated)="onControlsUpdated($event)"
                (pngExportEnabledUpdated)="onPngExportEnabledUpdated($event)"
                (tableExportEnabledUpdated)="onTableExportEnabledUpdated($event)"
                (graphWarningUpdated)="onGraphWarning($event)"
              ></bx-ngs-cluster-numbers-graph>
            }
            @case ('clusterNumbersNucleotide') {
              <bx-ngs-cluster-numbers-graph
                #exportable
                [aminoAcidOrNucleotide]="'nucleotide'"
                [documentID]="documentID$ | async"
                [graphIdForSidebar]="graphIdForSidebar$ | async"
                (controlsUpdated)="onControlsUpdated($event)"
                (pngExportEnabledUpdated)="onPngExportEnabledUpdated($event)"
                (tableExportEnabledUpdated)="onTableExportEnabledUpdated($event)"
                (graphWarningUpdated)="onGraphWarning($event)"
              ></bx-ngs-cluster-numbers-graph>
            }
            @case ('aminoAcidDistribution') {
              <bx-ngs-amino-acid-distribution-chart
                #exportable
                [documentID]="documentID$ | async"
                [graphIdForSidebar]="graphIdForSidebar$ | async"
                (controlsUpdated)="onControlsUpdated($event)"
                (pngExportEnabledUpdated)="onPngExportEnabledUpdated($event)"
                (tableExportEnabledUpdated)="onTableExportEnabledUpdated($event)"
                (graphWarningUpdated)="onGraphWarning($event)"
              ></bx-ngs-amino-acid-distribution-chart>
            }
            @case ('codonDistribution') {
              <bx-ngs-codon-distribution-chart
                #exportable
                [documentID]="documentID$ | async"
                [graphIdForSidebar]="graphIdForSidebar$ | async"
                (controlsUpdated)="onControlsUpdated($event)"
                (pngExportEnabledUpdated)="onPngExportEnabledUpdated($event)"
                (tableExportEnabledUpdated)="onTableExportEnabledUpdated($event)"
                (graphWarningUpdated)="onGraphWarning($event)"
              ></bx-ngs-codon-distribution-chart>
            }
            @case ('numberOfGenes') {
              <bx-ngs-number-of-genes-graph
                #exportable
                [documentID]="documentID$ | async"
                [graphIdForSidebar]="graphIdForSidebar$ | async"
                (controlsUpdated)="onControlsUpdated($event)"
                (pngExportEnabledUpdated)="onPngExportEnabledUpdated($event)"
                (tableExportEnabledUpdated)="onTableExportEnabledUpdated($event)"
                (graphWarningUpdated)="onGraphWarning($event)"
              ></bx-ngs-number-of-genes-graph>
            }
            @case ('geneCombinations') {
              <bx-ngs-gene-combinations-graph
                #exportable
                [documentID]="documentID$ | async"
                [graphIdForSidebar]="graphIdForSidebar$ | async"
                (controlsUpdated)="onControlsUpdated($event)"
                (pngExportEnabledUpdated)="onPngExportEnabledUpdated($event)"
                (tableExportEnabledUpdated)="onTableExportEnabledUpdated($event)"
                (graphWarningUpdated)="onGraphWarning($event)"
              ></bx-ngs-gene-combinations-graph>
            }
            @case ('geneFamilyUsage') {
              <bx-ngs-gene-family-usage-graph
                #exportable
                [documentID]="documentID$ | async"
                [graphIdForSidebar]="graphIdForSidebar$ | async"
                (controlsUpdated)="onControlsUpdated($event)"
                (pngExportEnabledUpdated)="onPngExportEnabledUpdated($event)"
                (tableExportEnabledUpdated)="onTableExportEnabledUpdated($event)"
                (graphWarningUpdated)="onGraphWarning($event)"
              ></bx-ngs-gene-family-usage-graph>
            }
            @case ('clusterSummaryTree') {
              <bx-ngs-cluster-summary-graph
                #exportable
                [documentID]="documentID$ | async"
                [graphIdForSidebar]="graphIdForSidebar$ | async"
                (controlsUpdated)="onControlsUpdated($event)"
                (pngExportEnabledUpdated)="onPngExportEnabledUpdated($event)"
                (tableExportEnabledUpdated)="onTableExportEnabledUpdated($event)"
                (graphWarningUpdated)="onGraphWarning($event)"
              ></bx-ngs-cluster-summary-graph>
            }
            @case ('clusterSummaryNetwork') {
              <bx-ngs-cluster-network
                #exportable
                [documentID]="documentID$ | async"
                [graphIdForSidebar]="graphIdForSidebar$ | async"
                (controlsUpdated)="onControlsUpdated($event)"
                (pngExportEnabledUpdated)="onPngExportEnabledUpdated($event)"
                (tableExportEnabledUpdated)="onTableExportEnabledUpdated($event)"
                (graphWarningUpdated)="onGraphWarning($event)"
              >
              </bx-ngs-cluster-network>
            }
          }
        </div>
      }
      @if (selectedGraph$ | async; as selectedGraph) {
        <bx-graph-sidebar
          class="controls"
          [controls]="controls$ | async"
          [allowControlUpdates]="true"
          [updateControlsAfterFirstChange]="true"
          [hideControls]="false"
          (toggle)="onSidebarToggled()"
          (controlsChanged)="onControlsChanged($event)"
        >
          <ng-template bxGraphSidebarHeader>
            <ng-template #loading>
              <bx-loading></bx-loading>
            </ng-template>
            <div class="d-flex flex-column w-100">
              <div class="row mb-3">
                <label class="col-4 col-form-label font-weight-bold" for="graphType">Graph:</label>
                <div class="col-8">
                  @if ((selectableGraphsLoading$ | async) === false) {
                    <select
                      id="graphType"
                      class="form-select"
                      [ngModel]="selectedGraph"
                      (ngModelChange)="onGraphChanged($event)"
                    >
                      @for (
                        selectableGraph of selectableGraphs$ | async;
                        track selectableGraph.id
                      ) {
                        <option [ngValue]="selectableGraph">
                          {{ selectableGraph.name }}
                        </option>
                      }
                    </select>
                  } @else {
                    <bx-loading></bx-loading>
                  }
                </div>
              </div>
              @if (shouldDisplaySelectionDisplayType$ | async) {
                <div class="row mb-3">
                  <div class="col-3 d-flex flex-column justify-content-center">
                    <label class="font-weight-bold" for="graphType">Source data:</label>
                  </div>
                  <div class="col-9">
                    <select
                      id="selectionType"
                      class="form-select"
                      [ngModel]="selectionDisplayType$ | async"
                      (ngModelChange)="onSelectionDisplayTypeChanged($event)"
                    >
                      @for (selectionOption of SelectionDisplayOptions; track selectionOption.id) {
                        <option [ngValue]="selectionOption">
                          {{ selectionOption.label }}
                        </option>
                      }
                    </select>
                  </div>
                </div>
              }
            </div>
          </ng-template>
        </bx-graph-sidebar>
      }
    }
  </div>
}
